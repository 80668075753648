<template>
    <b-modal :title="$t('config.users.user')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <b-overlay :show="loading">
                <validation-observer ref="validation">
                    <b-form-group :label="$t('form_data.email')">
                        <validation-provider #default="{ errors }" :name="$t('form_data.email')" rules="required|email">
                            <!-- Tukaj je v input nastavljen atribut autocomplete="new-password" ker je to edini način kako disable autofill v chromu. Kajkoli drugega vneseš ne dela -->
                            <b-form-input autocomplete="new-password" :disabled="editing"  v-model="addObject.email"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                </validation-observer>

                <div>
                    <div style="font-size: 20px; font-weight: bold;" class="mb-1">
                        {{$t('config.users.permissions.permissions')}}
                    </div>

                    <div class="d-flex justify-content-between mb-2">
                        <b-button variant="warning" @click="removeAllPermissions">
                            {{ $t('config.users.remove_all') }}
                        </b-button>

                        <b-button variant="primary" @click="addAllPermissions">
                            {{ $t('config.users.add_all') }}
                        </b-button>
                    </div>

                    <b-form-checkbox-group
                        v-model="addObject.permissions"
                    >
                        <div class="mb-1">
                            <div style="font-size: 14px; font-weight: bold;">
                                {{ $t('config.users.permissions.dashboard') }}
                            </div>

                            <div class="d-flex">
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.view') }}</span> <b-form-checkbox :value="$permissions.Dashboard" @change="updatedPermissions($permissions.Dashboard)"/>
                                </div>
                            </div>
                        </div>

                        <div class="mb-1">
                            <div style="font-size: 14px; font-weight: bold;">
                                {{ $t('config.users.permissions.material') }}
                            </div>

                            <div class="d-flex">
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.view-editing') }}</span> <b-form-checkbox :value="$permissions.InventoryManagement" @change="updatedPermissions($permissions.InventoryManagement)" />
                                </div>
                            </div>
                        </div>

                        <div class="mb-1">
                            <div style="font-size: 14px; font-weight: bold;">
                                {{ $t('general.invoices') }}
                            </div>

                            <div class="d-flex">
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.view') }}</span> <b-form-checkbox :value="$permissions.InvoicesRead" @change="updatedPermissions($permissions.InvoicesRead)"/>
                                </div>
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.editing') }}</span> <b-form-checkbox :value="$permissions.InvoicesWrite"  @change="updatedPermissions($permissions.InvoicesWrite)"/>
                                </div>
                                <span class="mr-1">{{ $t('config.users.permissions.reversal') }}</span> <b-form-checkbox :value="$permissions.InvoicesReversal"  @change="updatedPermissions($permissions.InvoicesReversal)"/>
                            </div>
                        </div>

                        <div class="mb-1">
                            <div style="font-size: 14px; font-weight: bold;">
                                {{ $t('config.users.permissions.reporting') }}
                            </div>

                            <div class="d-flex">
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.view') }}</span> <b-form-checkbox :value="$permissions.ReportingRead" @change="updatedPermissions($permissions.ReportingRead)"/>
                                </div>
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.editing') }}</span> <b-form-checkbox :value="$permissions.ReportingWrite" @change="updatedPermissions($permissions.ReportingWrite)"/>
                                </div>
                                <span class="mr-1">{{ $t('config.users.permissions.reversal') }}</span> <b-form-checkbox :value="$permissions.IssuedBillsReversal" @change="updatedPermissions($permissions.IssuedBillsReversal)"/>
                            </div>
                        </div>

                        <div class="mb-1">
                            <div style="font-size: 14px; font-weight: bold;">
                                {{ $t('config.users.permissions.data') }}
                            </div>

                            <div class="d-flex">
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.view') }}</span> <b-form-checkbox :value="$permissions.GeneralRead" @change="updatedPermissions($permissions.GeneralRead)"/>
                                </div>
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.editing') }}</span> <b-form-checkbox :value="$permissions.GeneralWrite" @change="updatedPermissions($permissions.GeneralWrite)"/>
                                </div>
                            </div>
                        </div>

                        <div class="mb-1">
                            <div style="font-size: 14px; font-weight: bold;">
                                {{ $t('config.users.permissions.keys') }}
                            </div>

                            <div class="d-flex">
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.view') }}</span> <b-form-checkbox :value="$permissions.ProductsRead" @change="updatedPermissions($permissions.ProductsRead)"/>
                                </div>
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.editing') }}</span> <b-form-checkbox :value="$permissions.ProductsWrite" @change="updatedPermissions($permissions.ProductsWrite)"/>
                                </div>
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.editing_prices') }}</span> <b-form-checkbox :value="$permissions.PricesWrite" @change="updatedPermissions($permissions.PricesWrite)"/>
                                </div>
                            </div>
                        </div>

                        <div class="mb-1">
                            <div style="font-size: 14px; font-weight: bold;">
                                {{ $t('general.config') }}
                            </div>

                            <div class="d-flex">
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.view') }}</span> <b-form-checkbox :value="$permissions.ConfigurationRead" @change="updatedPermissions($permissions.ConfigurationRead)"/>
                                </div>                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.editing') }}</span> <b-form-checkbox :value="$permissions.ConfigurationWrite" @change="updatedPermissions($permissions.ConfigurationWrite)"/>
                                </div>
                            </div>
                        </div>

                        <div class="mb-1">
                            <div style="font-size: 14px; font-weight: bold;">
                                {{ $t('config.users.permissions.pos_config') }}
                            </div>

                            <div class="d-flex">
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.view') }}</span> <b-form-checkbox :value="$permissions.PosConfigurationRead" @change="updatedPermissions($permissions.PosConfigurationRead)"/>
                                </div>
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.editing') }}</span> <b-form-checkbox :value="$permissions.PosConfigurationWrite" @change="updatedPermissions($permissions.PosConfigurationWrite)"/>
                                </div>
                            </div>
                        </div>

                        <div class="mb-1" v-if="returnablePackagingEnabled">
                            <div style="font-size: 14px; font-weight: bold;">
                                {{ $t('config.users.permissions.returnable_packaging') }}
                            </div>

                            <div class="d-flex">
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.view') }}</span> <b-form-checkbox :value="$permissions.ReturnablePackagingRead" @change="updatedPermissions($permissions.ReturnablePackagingRead)"/>
                                </div>
                                <div class="mr-2 d-flex">
                                    <span class="mr-1">{{ $t('config.users.permissions.editing') }}</span> <b-form-checkbox :value="$permissions.ReturnablePackagingWrite" @change="updatedPermissions($permissions.ReturnablePackagingWrite)"/>
                                </div>
                            </div>
                        </div>

                    </b-form-checkbox-group>

                </div>
            </b-overlay>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="validate" :disabled="loading">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
                <span v-if="loading">
                    <b-spinner class="ml-1" style="height: 15px; width: 15px"></b-spinner>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormCheckbox, BFormCheckboxGroup, BSpinner, BOverlay} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    import * as Sentry from '@sentry/vue'
    import {InvitationStatus} from '@/libs/enums/InvitationStatus'

    export default {
        components: {
            BFormCheckbox,
            BFormCheckboxGroup,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BSpinner,
            BOverlay,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                loading: false,
                modalActive: false,
                addObject: {
                    name: '',
                    email:'',
                    password: '',
                    permissions: []
                },
                required,
                email,
                editing: false,
                users: []
            }
        },
        computed:{
            returnablePackagingEnabled() {
                return this.$store.getters['setup/returnablePackagingEnabled']
            }
        },
        methods: {
            updatedPermissions(permission) {

                const mod = parseInt(permission % 100)

                if (mod === 1 && !this.addObject.permissions.includes(permission)) {
                    this.addObject.permissions = this.addObject.permissions.filter(ele => ele < permission || ele > permission + 10)
                } else {
                    if (mod >= 2) {
                        if (!this.addObject.permissions.includes(permission - 1)) {
                            this.addObject.permissions.push(permission - 1)
                        } else {
                            this.addObject.permissions = this.addObject.permissions.filter(ele => ele < permission + 1 || ele > permission + 10)
                        }
                    }

                    if (mod >= 3) {
                        if (!this.addObject.permissions.includes(permission - 2)) {
                            this.addObject.permissions.push(permission - 2)
                        } else {
                            this.addObject.permissions = this.addObject.permissions.filter(ele => ele < permission + 1 || ele > permission + 10)
                        }
                    }
                }

            },
            addAllPermissions() {
                const allPermissions = [
                    this.$permissions.Dashboard,
                    this.$permissions.InventoryManagement,
                    this.$permissions.InvoicesRead,
                    this.$permissions.InvoicesWrite,
                    this.$permissions.InvoicesReversal,
                    this.$permissions.ReportingRead,
                    this.$permissions.ReportingWrite,
                    this.$permissions.IssuedBillsReversal,
                    this.$permissions.GeneralRead,
                    this.$permissions.GeneralWrite,
                    this.$permissions.ProductsRead,
                    this.$permissions.ProductsWrite,
                    this.$permissions.PricesWrite,
                    this.$permissions.ConfigurationRead,
                    this.$permissions.ConfigurationWrite,
                    this.$permissions.PosConfigurationRead,
                    this.$permissions.PosConfigurationWrite,
                    this.$permissions.UserConfigurationRead,
                    this.$permissions.UserConfigurationWrite,
                    this.$permissions.ReturnablePackagingRead,
                    this.$permissions.ReturnablePackagingWrite
                ]

                this.addObject.permissions = []
                this.addObject.permissions.push(...allPermissions)
            },
            removeAllPermissions() {
                this.addObject.permissions = []
            },
            open(data = null) {
                this.loadUsers()
                if (data !== null) {
                    this.addObject = data
                    this.editing = true
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        email:'',
                        password: '',
                        permissions: []
                    }
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            checkStatusByEmail(arr, email) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].email === email) {
                        if (arr[i].status === InvitationStatus.ConfirmationInProgress) {
                            return false
                        } else {
                            return true
                        }
                    }
                }
                return true
            },
            async add() {
              
                if (!this.checkStatusByEmail(this.users, this.addObject.email)) {
                    this.$printError(this.$t('general.user_already_added'))
                    return
                }

                try {
                    this.loading = true
                    const response = await this.$http.post('/api/client/v1/registration/create_invitation', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        email:'',
                        password: ''
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                } finally {
                    this.loading = false
                }
            },
            async edit() {
                try {
                    this.loading = true
                    await this.$http.put(`/api/client/v1/users/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        email:'',
                        password: ''
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.loading = false
                }
            },
            async loadUsers() {
                try {
                    const res = await this.$http.get('/api/client/v1/users/')
                    this.users = res.data.items ?? []

           
                } catch (err) {
                    Sentry.captureException(err)
                }
            }
        }
    }
</script>